// 上に戻るボタン
function toTopBtn() {
 return ( window.pageYOffset !== undefined ) ? window.pageYOffset: document.documentElement.scrollTop;
}

//トップに戻るボタンの要素を取得
const topButton = document.getElementById( 'to-top' );

//ボタンの表示・非表示
window.onscroll = function() {
  ( toTopBtn() > 500 ) ? topButton.classList.add( 'is-show' ): topButton.classList.remove( 'is-show' );
};

//トップに移動する関数
function scrollToTop() {
  let scrolled = toTopBtn();
  window.scrollTo( 0, Math.floor( scrolled / 2 ) );
  if ( scrolled > 0 ) {
    window.setTimeout( scrollToTop, 30 );
  }
};
// トップへ戻るボタン

//イベント登録
topButton.onclick = function() {
  scrollToTop();
};

// アンカーリンク
const smoothScroll = document.querySelectorAll('a[href^="#"]');
for (let i = 0; i < smoothScroll.length; i++){
  smoothScroll[i].addEventListener('click', (e) => {
    e.preventDefault();
    let href = smoothScroll[i].getAttribute('href');
     let targetElement = document.getElementById(href.replace('#', ''));
    const rect = targetElement.getBoundingClientRect().top;
    const offset = window.pageYOffset;
    const target = rect + offset;//ヘッダー固定じゃない場合
    // const gap = 60;//ヘッダー固定の場合ヘッダーの高さを入力
    // const target = rect + offset - gap;//ヘッダーの高さ分引く
    window.scrollTo({
      top: target,
      behavior: 'smooth',
    });
  });
}
