
$(function(){
  let min_height = 80; //高さ調整
  let ani_duration = 300; //アニメーション実行時間（ミリ秒）

  $('.moreContent').each(function(index) {
    if ($(this).height()>min_height + 50) {//min_height + 50px以上になったら追加
      $(this).height(min_height).append('<div class="moreContent_btn"></div>');
    }
  });

  $('.moreContent_btn').click(function(){
    let athis = $(this);
    let show_text = athis.parent('.moreContent');
    let original_height = show_text.css({height : 'auto'}).height();

    if(show_text.hasClass('is-open')){
      //--CLOSE--
      let scroll_offset = $("html,body").scrollTop() - original_height + min_height;

      $("html,body").animate({ scrollTop: scroll_offset }, ani_duration);
      show_text.animate({height:min_height}, ani_duration, function(){
      });
      show_text.removeClass('is-active').removeClass('is-open');
    }else{
        //--OPEN--
        show_text.height(min_height).animate({height:original_height},ani_duration, function(){
        show_text.height('auto').addClass('is-open');
      });
      show_text.addClass('is-active');
    }
  });
});
